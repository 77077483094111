import { Form, Modal, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import FieldProjectForAddItem from '../modules/schedule/pages/SchedulePage/fieldProjectForAddItem';
import { FieldPhase } from 'smx-components';
import { UploadFormItem } from './uploadFormItem';
import { OnTasksImport } from 'smx-components/dist/esm/src/types';
import { useApi } from '../api/useApi';
import { getErrorMessage } from '../error/get-error-message';
import FieldScheduleItemForAddItem from '../modules/schedule/pages/SchedulePage/fieldScheduleItemForAddItem';
import { ImportTemplateButton } from './importTeplateButton';

type ImportTasksType = Parameters<OnTasksImport>[0];

export interface TasksImportModalHandles {
    onTasksImport: OnTasksImport;
}

export const TasksImportModal = forwardRef<TasksImportModalHandles>((_props, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const refListProject = useRef<React.FC<any>>(null);
    const promiseRef = useRef<{
        resolve?: () => void;
        reject?: (reason?: any) => void;
    }>({});
    const [confirmLoading, setConfirmLoading] = useState(false);

    const callbackRef = useRef<ImportTasksType>();
    const [notificationApi, contextHolder] = notification.useNotification();
    const { schedule } = useApi();

    const values = Form.useWatch([], form);

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            const { parentId, ...formValues } = await form.validateFields();

            const updatedValues = {
                ...formValues,
                phaseId: Number(values.phaseId),
                scheduleItemId: parentId,
            };

            if (callbackRef.current) {
                console.log(callbackRef.current.toString());
                await callbackRef.current(updatedValues);
            }
            promiseRef.current.resolve?.();
            setIsModalOpen(false);
            form.resetFields();
            notificationApi.success({
                message: 'Tasks imported successfully',
            });
        } catch (error: unknown) {
            notificationApi.error({
                message: getErrorMessage(error),
            });
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        promiseRef.current.resolve?.();
    };

    useImperativeHandle(ref, () => ({
        onTasksImport: (importTasks: ImportTasksType) => {
            setIsModalOpen(true);
            callbackRef.current = importTasks;
            return new Promise((resolve, reject) => {
                promiseRef.current = { resolve, reject };
            });
        },
    }));

    return (
        <>
            {contextHolder}
            <Modal
                title="Add new tasks"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={2000}
                width={300}
                confirmLoading={confirmLoading}
            >
                <Form
                    name="newTasksImportForm"
                    layout="vertical"
                    form={form}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <FieldProjectForAddItem refListProject={refListProject} />
                    <FieldPhase
                        name={'phaseId'}
                        parentRef={refListProject as any}
                        required
                        disabled={!values?.['projectId']}
                    />
                    <FieldScheduleItemForAddItem />
                    <UploadFormItem />
                </Form>
                <ImportTemplateButton downloadTemplate={() => schedule.getImportTasksCsvTemplate()} />
            </Modal>
        </>
    );
});
