import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { Category, Document } from "interfaces/documents";
import { Copy } from "./BulkActions/Copy";
import { Delete } from "./BulkActions/Delete";
import { Move } from "./BulkActions/Move";

const ActionDropdown = ({
    fetchData,
    selected,
}: {
    fetchData: () => void,
    selected: (Category | Document)[],
}) => {
    const bulkCopy = Copy(selected.filter((item) => item.type === 'document') as Document[], fetchData);
    const bulkDelete = Delete(selected, fetchData);
    const bulkMove = Move(selected, fetchData);

    return (
        <>
            <Dropdown menu={{ items: [bulkDelete, bulkCopy, bulkMove] }}>
                <Button>
                    <Space>
                        Actions
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </>
    )
}

export default ActionDropdown;
