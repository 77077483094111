import { EditOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { renameDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';
import { renameCategory } from 'slices/categoriesActions';

export const Rename = (
    row: Category | Document,
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();

    const rename = (item: string) => {
        if (row?.type === 'category') {
            dispatch(renameCategory({ id: (row as Category).uuid, name: item }))
                .unwrap();
        } else {
            dispatch(renameDocument({ id: (row as Document).id, name: item }))
                .unwrap();
        }
    };

    return {
        key: 'Rename',
        label: 'Rename',
        onClick: () => {
            openModal('renameModal', {
                entity: row,
            }, {
                onSubmit: (value: string) => {
                    if (value) {
                        rename(value);
                        fetchData();
                    }
                },
            });
        },
        icon: <EditOutlined />,
    };
}