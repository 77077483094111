import { CopyOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { copyDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';

export const Copy = (
    rows: Document[],
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();
    const copyItem = (destination: Category | null) => {
        rows.forEach((item) => {
            dispatch(copyDocument({
                id: item.id,
                category_id: destination?.id || null,
            }));
        });

        fetchData();
    };

    return {
        key: 'Copy',
        label: 'Copy',
        onClick: () => {
            openModal('categoriesTreeModal', {
                title: 'Copy to...',
                submitLabel: 'Copy',
                cancelLabel: 'Cancel',
            }, {
                onSubmit: (value: Category|null) => {
                    copyItem(value);
                }
            });
        },
        icon: <CopyOutlined />,
        disabled: !rows.length,
    };
}