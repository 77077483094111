import { DeleteOutlined } from '@ant-design/icons';
import { Category, Document } from 'interfaces/documents';
import { useModal } from 'hooks/useModal';
import { softDeleteCategory } from 'slices/categoriesActions';
import { softDeleteDocument } from 'slices/documentsActions';
import { useAppDispatch } from 'utilities/hooks';

export const Delete = (
    rows: (Category | Document)[],
    fetchData: () => void
) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModal();

    const deleteItem = () => {
        if (!rows) return;

        rows.forEach((item) => {
            if ((item as Category).type === 'category') {
                dispatch(softDeleteCategory((item as Category).id));
            } else {
                dispatch(softDeleteDocument((item as Document).id));
            }
        });

        fetchData();
    };

    return {
        key: 'Delete',
        label: 'Delete',
        onClick: () => {
            openModal('confirmationModal', {
                text: 'Are you sure want to delete items?',
            }, {
                onConfirm: () => {
                    deleteItem();
                }
            });
        },
        icon: <DeleteOutlined />,
        disabled: !rows.length,
    };
}