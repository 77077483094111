import { Table } from 'antd';
import TableColumns from './TableColumns';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import { useRef } from 'react';
import { TableRowSelection } from 'antd/es/table/interface';
import { Category, Document } from 'interfaces/documents';
import { useAppDispatch } from 'utilities/hooks';
import { BaseModalRef } from 'modals/BaseModal';
import { setDocument } from 'slices/documentSlice';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import { useModal } from 'hooks/useModal';

const BaseTableDocuments = ({
    setSelectedItems,
    shared = false,
    deleted = false,
}: {
    setSelectedItems: (items: (Category | Document)[]) => void;
    shared?: boolean;
    deleted?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { openModal: openDocumentMarkupModal } = useModal();

    const { list, loading, fetchData, navigateTo } = useDocumentsPage({ isShared: shared, isDeleted: deleted });

    const columns = TableColumns({ fetchData, shared, deleted });

    const rowSelection: TableRowSelection<Document | Category> = {
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedItems(list.filter((item) => newSelectedRowKeys.includes(item.id)));
        },
    };

    const handleCategory = (category: Category) => {
        if (!navigateTo) return;
        navigateTo(category.id);
    };

    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        openDocumentMarkupModal('documentMarkupModal', {
            readonly: deleted,
        });
    };

    return (
        <>
            <Table<Category | Document>
                columns={columns}
                dataSource={list}
                onRow={(row) => ({
                    onClick: () => {
                        if (row.type === 'category' && !deleted) {
                            handleCategory(row as Category);
                        } else if (row.type === 'document') {
                            handleDocumentOpen(row as Document);
                        }
                    },
                })}
                loading={loading}
                pagination={false}
                rowSelection={rowSelection}
            />
        </>
    );
};

export default BaseTableDocuments;
